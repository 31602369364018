import Action from "./Action";
import Location from "../Main/Location";
import {Serialization} from "../Main/Serializer";

export default class GpxImportAction implements Action {

    constructor(readonly routes: Route[], readonly locations: Location[]) {

    }

    public apply() {
        for (const location of this.locations) {
            location.locationCollection.attachLocation(location, location.locationCollection.getLocations().length);
        }

        for (const route of this.routes) {
            route.routeCollection.attachRoute(route, route.routeCollection.getRoutes().length);
        }
    }

    public revert() {
        for (const route of this.routes.reverse()) {
            route.routeCollection.detachRoute(route);
        }

        for (const location of this.locations.reverse()) {
            location.locationCollection.detachLocation(location);
        }
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            locations: this.locations.map((location) => location.id),
            routes: this.routes.map((route) => route.id),
        };
    }
}
