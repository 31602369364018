import Route from "../Main/Route.js";
import Location from "../Main/Location.js";
import {fromLonLat} from "ol/proj";
import UserInterface from "../Main/UserInterface.js";
import GpxImportAction from "../ActionHistory/GpxImportAction.js";
import {LineString} from "ol/geom";

export class GpxImportException extends Error {}

export class GpxImporter {
    readonly wpts;
    readonly rtes;
    readonly trks;
    readonly invalid;

    static async fromFile(file: File) {
        return new GpxImporter(file.name, await file.text())
    }

    constructor(readonly filename: string, readonly contents: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.contents.trim(), "application/xml");
        if (doc.querySelector("parsererror")) {
            throw new GpxImportException('Invalid GPX file: Could not parse file');
        }

        const gpxNode = doc.documentElement;
        if (!gpxNode || gpxNode.nodeName.toLowerCase() !== "gpx") {
            throw new GpxImportException('Invalid GPX file: Could not find GPX node');
        }

        let invalid = 0;
        const wpts = [];
        const rtes = [];
        const trks = [];
        for (let i = 0; i < gpxNode.children.length; i++) {
            const item = gpxNode.children[i];
            const tag = item.nodeName.toLowerCase();

            if (tag === 'wpt') {
                const wpt = this.parseWpt(item);
                if (wpt === null) {
                    invalid++;
                } else {
                    wpts.push(wpt);
                }
            } else if (tag === 'rte') {
                const rte = this.parseRte(item);
                if (rte === null) {
                    invalid++;
                } else {
                    rtes.push(rte);
                }
            } else if (tag === 'trk') {
                const trk = this.parseTrk(item);
                if (trk === null) {
                    invalid++;
                } else {
                    trks.push(trk);
                }
            }
        }

        this.wpts = wpts;
        this.rtes = rtes;
        this.trks = trks;
        this.invalid = invalid;
    }

    private parseWpt(item: Element) {
        if (!item.hasAttribute('lon') || !item.hasAttribute('lat')) {
            return null;
        }

        const lon = item.getAttribute('lon');
        const lat = item.getAttribute('lat');

        if (isNaN(lon) || isNaN(lat)) {
            return null;
        }

        return {
            lonlat: [+lon, +lat],
            name: item.querySelector('name')?.textContent,
            time: item.querySelector('time')?.textContent,
        };
    }

    private parseRte(item: Element) {
        let name = null;
        let startTime = null;
        let endTime = null;
        const rtepts = [];
        for (let i = 0; i < item.children.length; i++) {
            const rteChild = item.children[i];
            const rteChildTag = rteChild.nodeName.toLowerCase();

            if (rteChildTag === 'name') {
                name = rteChild.textContent;
            } else if (rteChildTag === 'rtept') {
                const lon = rteChild.getAttribute('lon');
                const lat = rteChild.getAttribute('lat');

                if (isNaN(lon) || isNaN(lat)) {
                    return null;
                }

                rtepts.push([+lon, +lat]);

                const time = rteChild.querySelector('time')?.textContent;
                if (time) {
                    if (startTime === null) {
                        startTime = time;
                    }
                    endTime = time;
                }
            }
        }

        return {
            name: name,
            rtepts: rtepts,
            startTime: startTime,
            endTime: endTime,
        };
    }

    private parseTrk(item: Element) {
        let name = null;
        let startTime = null;
        let endTime = null;
        const trkpts = [];
        for (let i = 0; i < item.children.length; i++) {
            const trkChild = item.children[i];
            const trkChildTag = trkChild.nodeName.toLowerCase();

            if (trkChildTag === 'name') {
                name = trkChild.textContent;
            } else if (trkChildTag === 'trkseg') {
                for (let j = 0; j < trkChild.children.length; j++) {
                    const trkSegChild = trkChild.children[j];
                    const trkSegChildTag = trkSegChild.nodeName.toLowerCase();

                    if (trkSegChildTag === 'trkpt') {
                        const lon = trkSegChild.getAttribute('lon');
                        const lat = trkSegChild.getAttribute('lat');

                        if (isNaN(lon) || isNaN(lat)) {
                            return null;
                        }

                        trkpts.push([+lon, +lat]);

                        const time = trkSegChild.querySelector('time')?.textContent;
                        if (time) {
                            if (startTime === null) {
                                startTime = time;
                            }
                            endTime = time;
                        }
                    }
                }
            }
        }

        return {
            name: name,
            trkpts: trkpts,
            startTime: startTime,
            endTime: endTime,
        };
    }

    private getCoordinatesFromLonLats(lonlats: [number, number][], simplification_tolerance: number): [number, number][] {
        let geometry = new LineString(
            lonlats.map((lonlat) => fromLonLat(lonlat))
        );

        if (simplification_tolerance > 0) {
            geometry = geometry.simplify(simplification_tolerance);
        }

        return geometry.getCoordinates();
    }

    import(userInterface: UserInterface, includeWpts: bool[], includeRtes: bool[], includeTrks: bool[], simplification_tolerance: number) {
        if (includeWpts.length != this.wpts.length) {
            throw new Error('Invalid waypoints include list length');
        }
        if (includeRtes.length != this.rtes.length) {
            throw new Error('Invalid routes include list length');
        }
        if (includeTrks.length != this.trks.length) {
            throw new Error('Invalid tracks include list length');
        }

        const locations = [];
        const routes = [];
        for (let i = 0; i < includeWpts.length; i++) {
            if (!includeWpts[i]) {
                continue;
            }

            const location = new Location(userInterface.getLocationCollection(), fromLonLat(this.wpts[i].lonlat));
            location.setName(
                this.wpts[i].name?.trim()
                || 'Punt ' + (i+1) + ' (' + this.filename + ')'
            );

            locations.push(location);
        }

        for (let i = 0; i < includeRtes.length; i++) {
            if (!includeRtes[i]) {
                continue;
            }

            const route = new Route(userInterface.getRouteCollection());
            route.setColor(userInterface.newColor());
            route.setName(
                this.rtes[i].name?.trim()
                || 'Route ' + (i+1) + ' (' + this.filename + ')'
            );
            route.setCoordinates(this.getCoordinatesFromLonLats(this.rtes[i].rtepts, simplification_tolerance));

            routes.push(route);
        }

        for (let i = 0; i < includeTrks.length; i++) {
            if (!includeTrks[i]) {
                continue;
            }

            const route = new Route(userInterface.getRouteCollection());
            route.setColor(userInterface.newColor());
            route.setName(
                this.trks[i].name?.trim()
                || 'Route ' + (i+1) + ' (' + this.filename + ')'
            );
            route.setCoordinates(this.getCoordinatesFromLonLats(this.trks[i].trkpts, simplification_tolerance));

            routes.push(route);
        }

        userInterface.actionHistory.addAction(new GpxImportAction(
            routes,
            locations,
        ));

        userInterface.getMap().fitTo([], routes, locations);
    }
}
