<template>
  <div
      class="modal"
      tabindex="-1"
      role="dialog"
      id="upload_gpx_modal"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">GPX importeren</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="stage == 1">
            <div class="form-group">
              <label for="upload_gpx_file" class="form-label">Selecteer bestand (.GPX)</label>
              <input type="file" class="form-control" id="upload_gpx_file" v-on:change="upload" accept=".gpx">
            </div>
          </div>

          <div v-if="stage == 2">
            <p>
              Gevonden:
            </p>
            <ul>
              <li v-if="gpxImporter.wpts.length > 0">{{ gpxImporter.wpts.length }} waypoints (punten)</li>
              <li v-if="gpxImporter.rtes.length > 0">{{ gpxImporter.rtes.length }} routes (routes)</li>
              <li v-if="gpxImporter.trks.length > 0">{{ gpxImporter.trks.length }} sporen (routes)</li>
              <li v-if="gpxImporter.invalid > 0" class="text-danger">{{ gpxImporter.invalid }} onbekende of ongeldige items</li>
            </ul>

            <b v-if="gpxImporter.wpts.length > 0">Waypoints</b>
            <div class="table-responsive" v-if="gpxImporter.wpts.length > 0">
              <table class="table gsm-paper-list">
                <thead>
                <tr>
                  <th class="table-col-checkbox">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" v-model="allWpts" id="upload_gpx_all_wpts" v-on:change="changeAllWpts()">
                    </div>
                  </th>
                  <th class="table-col-name">Naam</th>
                  <th>Tijd</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(wpt, index) in gpxImporter.wpts">
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" v-model="includeWpts[index]" v-on:change="changeIncludeWpts()">
                    </div>
                  </td>
                  <td>{{ wpt.name }}</td>
                  <td>{{ wpt.time }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <b v-if="gpxImporter.rtes.length > 0">Routes</b>
            <div class="table-responsive" v-if="gpxImporter.rtes.length > 0">
              <table class="table gsm-paper-list">
                <thead>
                <tr>
                  <th class="table-col-checkbox">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" v-model="allRtes" id="upload_gpx_all_rtes" v-on:change="changeAllRtes()">
                    </div>
                  </th>
                  <th class="table-col-name">Naam</th>
                  <th>Waypoints</th>
                  <th>Start</th>
                  <th>Eind</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(rte, index) in gpxImporter.rtes">
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" v-model="includeRtes[index]" v-on:change="changeIncludeRtes()">
                    </div>
                  </td>
                  <td>{{ rte.name }}</td>
                  <td>{{ rte.rtepts.length }}</td>
                  <td>{{ rte.startTime }}</td>
                  <td>{{ rte.endTime }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <b v-if="gpxImporter.trks.length > 0">Sporen</b>
            <div class="table-responsive" v-if="gpxImporter.trks.length > 0">
              <table class="table gsm-paper-list">
                <thead>
                <tr>
                  <th class="table-col-checkbox">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" v-model="allTrks" id="upload_gpx_all_trks" v-on:change="changeAllTrks()">
                    </div>
                  </th>
                  <th class="table-col-name">Naam</th>
                  <th>Waypoints</th>
                  <th>Start</th>
                  <th>Eind</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(trk, index) in gpxImporter.trks">
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" v-model="includeTrks[index]" v-on:change="changeIncludeTrks()">
                    </div>
                  </td>
                  <td>{{ trk.name }}</td>
                  <td>{{ trk.trkpts.length }}</td>
                  <td>{{ trk.startTime }}</td>
                  <td>{{ trk.endTime }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div v-if="gpxImporter.rtes.length > 0 || gpxImporter.trks.length > 0">
              <b>Instellingen</b>
              <div class="form-group">
                <label for="route_simplification_tolerance" class="form-label">Routes vereenvoudigen (afstandstolerantie)</label>
                <div class="input-group">
                  <input type="number" min="0" step="1" class="form-control" id="route_simplification_tolerance" v-model="simplification_tolerance">
                  <span class="input-group-text">m</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between" v-if="stage == 2">
          <button type="button" class="btn btn-outline-secondary" v-on:click="backToStage1">&laquo; Terug</button>

          <button type="button" class="btn btn-primary" v-on:click="performImport">Importeren</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Container from "../Main/Container";
import $ from "jquery";
import UserInterface from "../Main/UserInterface";
import {bsModal} from "../Util/vueFunctions";
import {GpxImporter, GpxImportException} from "../Util/GpxImporter.js";

function evaluateChecks(checks: bool[]): bool|null {
  let all = true;
  let any = false;

  for (let check of checks) {
    if (check) {
      any = true;
    } else {
      all = false;
    }
  }

  if (all) {
    return true;
  } else if (any) {
    return null;
  } else {
    return false;
  }
}

export default {
  props: {
    listenId: String,
    userInterface: <UserInterface>null,
  },
  data () {
    $(() => {
      $('#' + this.listenId).on('click', (e, options) => {
        options = options || {};

        bsModal('#upload_gpx_modal').show();
      });


      const self = this;
      document.getElementById('upload_gpx_modal').addEventListener('hidden.bs.modal', function (e) {
        self.backToStage1();
      });
    });

    return {
      container: Container,
      stage: 1,
      gpxImporter: null,
      includeWpts: null,
      includeRtes: null,
      includeTrks: null,
      allWpts: null,
      allRtes: null,
      allTrks: null,
      simplification_tolerance: 1,
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    changeIncludeWpts(index: number) {
        const allWpts = evaluateChecks(this.includeWpts);
        this.allWpts = allWpts !== false;
        $('#upload_gpx_all_wpts').prop('indeterminate', allWpts === null);
    },
    changeIncludeRtes(index: number) {
        const allRtes = evaluateChecks(this.includeRtes);
        this.allRtes = allRtes !== false;
        $('#upload_gpx_all_rtes').prop('indeterminate', allRtes === null);
    },
    changeIncludeTrks(index: number) {
        const allTrks = evaluateChecks(this.includeTrks);
        this.allTrks = allTrks !== false;
        $('#upload_gpx_all_trks').prop('indeterminate', allTrks === null);
    },
    changeAllWpts() {
      for (let i = 0; i < this.includeWpts.length; i++) {
        this.includeWpts[i] = this.allWpts;
      }
    },
    changeAllRtes() {
      for (let i = 0; i < this.includeRtes.length; i++) {
        this.includeRtes[i] = this.allRtes;
      }
    },
    changeAllTrks() {
      for (let i = 0; i < this.includeTrks.length; i++) {
        this.includeTrks[i] = this.allTrks;
      }
    },
    async upload(event): void {
      this.gpxImporter = null;
      this.includeWpts = null;
      this.includeRtes = null;
      this.includeTrks = null;
      this.allWpts = null;
      this.allRtes = null;
      this.allTrks = null;
      this.simplification_tolerance = 1;

      try {
        this.gpxImporter = await GpxImporter.fromFile(event.target.files[0]);
        this.includeWpts = new Array(this.gpxImporter.wpts.length).fill(true);
        this.includeRtes = new Array(this.gpxImporter.rtes.length).fill(true);
        this.includeTrks = new Array(this.gpxImporter.trks.length).fill(true);
        this.allWpts = true;
        this.allRtes = true;
        this.allTrks = true;
        this.stage = 2;
      } catch (e) {
        if (e instanceof GpxImportException) {
          alert(e.message);
        } else {
          throw e;
        }
      }
    },
    backToStage1() {
      this.stage = 1;
      this.gpxImporter = null;
      this.includeWpts = null;
      this.includeRtes = null;
      this.includeTrks = null;
      this.allWpts = null;
      this.allRtes = null;
      this.allTrks = null;
      this.simplification_tolerance = 1;
    },
    performImport() {
      this.gpxImporter.import(this.userInterface, this.includeWpts, this.includeRtes, this.includeTrks, +this.simplification_tolerance);

      bsModal('#upload_gpx_modal').hide();
    }
  }
};
</script>

<style scoped>
.table-col-checkbox {
  width: 8%;
}

.table-col-name {
  min-width: 20%;
}
</style>
