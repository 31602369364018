import {App} from "@vue/runtime-core";
import {createApp as createVueApp} from "@vue/runtime-dom";
import ErrorLogger from "./ErrorLogger";
import CutoutSettingsModal from "../components/CutoutSettingsModal.vue";
import CutoutTemplates from "../components/CutoutTemplates.vue";
import Bookmarks from "../components/Bookmarks.vue";
import SearchBar from "../components/SearchBar.vue";
import CoordinatePanel from "../components/CoordinatePanel.vue";
import SketchRoutePanel from "../components/SketchRoutePanel.vue";
import GeneralSettingsModal from "../components/GeneralSettingsModal.vue";
import RouteSettingsModal from "../components/RouteSettingsModal.vue";
import LocationSettingsModal from "../components/LocationSettingsModal.vue";
import RouteIntermediatesPanel from "../components/RouteIntermediatesPanel.vue";
import UploadGpxModal from "../components/UploadGpxModal.vue";
import {Modal} from "bootstrap";

export function createVue(el, props): App {
    const app = createVueApp(props);

    ErrorLogger.bindToVue(app);

    app.component('CutoutSettingsModal', CutoutSettingsModal);
    app.component('CutoutTemplates', CutoutTemplates);
    app.component('Bookmarks', Bookmarks);
    app.component('SearchBar', SearchBar);
    app.component('CoordinatePanel', CoordinatePanel);
    app.component('SketchRoutePanel', SketchRoutePanel);
    app.component('GeneralSettingsModal', GeneralSettingsModal);
    app.component('RouteSettingsModal', RouteSettingsModal);
    app.component('LocationSettingsModal', LocationSettingsModal);
    app.component('RouteIntermediatesPanel', RouteIntermediatesPanel);
    app.component('UploadGpxModal', UploadGpxModal);

    app.mount(el);

    return app;
}

export function bsModal(selector) {
    const element = typeof selector === 'string' ? document.querySelector(selector) : selector;

    return Modal.getOrCreateInstance(element);
}
